<template>
    <div class="wrapper">
        <div class="container-fluid my-md-4 mb-5 mt-0 px-0">
            <Form class="no-lr-padding" autocomplete="off" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <div class="row">
                    <div class="col-sm-12 col-xl-7 h-100">
                        <div class="row d-flex">
                            <div class="col-12 col-md-6">
                                <div class="form-group mb-3">
                                    <Field name="firstName" id="fname" type="text" v-model="model.firstName" class="form-control rounded-0" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" :class="{ 'is-invalid': errors.firstName }" />
                                    <label for="fname" class="regular">First Name <span>*</span></label>
                                    <div class="invalid-feedback">{{errors.firstName}}</div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group mb-3">
                                    <Field type="text" id="lname" name="lastName" v-model="model.lastName" class="form-control rounded-0" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" :class="{ 'is-invalid': errors.lastName }" />
                                    <label for="lname" class="regular">Last Name <span>*</span></label>
                                    <div class="invalid-feedback">{{errors.lastName}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="form-group mb-3">
                                    <Field type="text" id="email" name="emailAddress" v-model="model.emailAddress" class="form-control rounded-0" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" :class="{ 'is-invalid': errors.emailAddress }" />
                                    <label for="email" class="regular">Email Address <span>*</span></label>
                                    <div class="invalid-feedback">{{errors.emailAddress}}</div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group mb-3 custom-mobile">
                                    <div id="mob" class="mobile-wrapper">
                                        <Field aria-label="Code" class="form-control form-select code rounded-0" v-model="model.code" name="code" as="select" :class="{ 'is-invalid': errors.code }">
                                            <option disabled value="">Code</option>
                                            <option v-for="(country, index) in countries" :value="country.dial_code" :key="index">+{{ country.dial_code }}</option>
                                        </Field>
                                        <Field type="text" v-maska data-maska="### ### ####" placeholder="eg: 082 000 0000" v-model="model.mobile" id="mnumber" name="mobile" class="mobile rounded-0" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" :class="{ 'is-invalid': errors.mobile }" />
                                    </div>
                                    <label for="mob" class="regular">Mobile Number <span>*</span></label>
                                    <div class="errors">
                                        <div class="invalid-feedback">{{errors.code}}</div>
                                        <div class="invalid-feedback">{{errors.mobile}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 mb-3">
                                <div class="input-group custom-box">
                                    <label for="password" class="regular">Password <span>*</span></label>
                                    <Field v-if="showPassword" type="text" name="password" v-model="model.password" class="form-control rounded-0" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" :class="{ 'is-invalid': errors.password }" />
                                    <Field v-else type="password" name="password" v-model="model.password" class="form-control rounded-0" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" :class="{ 'is-invalid': errors.password }" />
                                    <div class="input-group-append">
                                        <button type="button" class="button form-control rounded-0" @click.prevent="toggleShow">
                                            <span class="icon is-small is-right grey">
                                                <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                            </span>
                                        </button>
                                    </div>
                                    <div class="invalid-feedback">{{errors.password}}</div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <div class="input-group custom-box">
                                    <label for="password" class="regular">Retype Password <span>*</span></label>
                                    <Field v-if="showConfirmPassword" type="text" name="retypePassword" v-model="model.confirmedPassword" class="form-control rounded-0" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" :class="{ 'is-invalid': errors.retypePassword }" />
                                    <Field v-else type="password" name="retypePassword" v-model="model.confirmedPassword" class="form-control rounded-0" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" :class="{ 'is-invalid': errors.retypePassword }" />
                                    <div class="input-group-append">
                                        <button type="button" class="button form-control rounded-0" @click.prevent="togglePasswordShow">
                                            <span class="icon is-small is-right grey">
                                                <i class="fas" :class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="apiErrors">{{errors.retypePassword}}</div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-6 mb-3">
                                <div class="form-group mb-3">
                                    <Field aria-label="Country" class="form-control form-select rounded-0" v-model="model.country" name="country" as="select" :class="{ 'is-invalid': errors.country }">
                                        <option disabled value="">Select Country of Origin</option>
                                        <option v-for="(country, index) in countries" :value="country.name" :key="index">{{ country.name }}</option>
                                    </Field>
                                    <label for="country" class="regular">Country *</label>
                                    <div class="invalid-feedback">{{errors.country}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 mb-2">
                                <div class="form-group">
                                    <label for="password" class="regular text-md-start text-center my-md-0 my-3">What pet do you have? <span>*</span></label>
                                </div>
                            </div>
                            <div class="col-12 col-md-8 mb-3">
                                <div class="pet-selector">
                                    <div class="pet-wrap" as="radio">
                                        <Field type="radio" id="canine" name="pet" v-model="model.ownedPet" value="Canine" :class="{ 'is-invalid': errors.pet }" />
                                        <label class="pet-cc dog" for="canine"></label>
                                    </div>
                                    <div class="pet-wrap">
                                        <Field type="radio" id="feline" name="pet" v-model="model.ownedPet" value="Feline" :class="{ 'is-invalid': errors.pet }" />
                                        <label class="pet-cc cat" for="feline"></label>
                                    </div>
                                    <div class="pet-wrap">
                                        <Field type="radio" id="both" name="pet" v-model="model.ownedPet" value="Both" :class="{ 'is-invalid': errors.pet }" />
                                        <label class="pet-cc both text-uppercase" for="both">
                                            Blessed<br />
                                            With<br />
                                            Both
                                        </label>
                                    </div>
                                    <div class="pet-wrap">
                                        <Field type="radio" id="looking" name="pet" v-model="model.ownedPet" value="LookingForLove" :class="{ 'is-invalid': errors.pet }" />
                                        <label class="pet-cc looking text-uppercase" for="looking">
                                            Still<br />
                                            looking<br />
                                            for love
                                        </label>
                                    </div>
                                    <div class="errors">
                                        <div class="invalid-feedback">
                                            {{errors.pet}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="invalid-feedback">{{errors.pet}}</div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="form-check">
                                <Field class="form-check-input rounded-0" type="checkbox" v-model="model.popiConsentGiven" id="consent" name="Consent" value="true" :unchecked-value="false" :class="{ 'is-invalid': errors.Consent }" />
                                <label class="form-check-label rounded-0">
                                    I, the data subject, have read and understood this <a href="https://res.cloudinary.com/dqwmahefm/image/upload/v1669023925/pawpedia/static/section-18-consumer-consent-form-v2_uotsks.pdf" class="grey" target="_blank">consent notice</a>
                                    and consent to the processing of my personal information*
                                </label>
                                <div class="invalid-feedback">{{errors.Consent}}</div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="form-check">
                                <Field class="form-check-input rounded-0" type="checkbox" v-model="model.terms" id="terms" name="acceptTerms" value="true" :unchecked-value="false" :class="{ 'is-invalid': errors.acceptTerms }" />
                                <label class="form-check-label rounded-0">
                                    I agree to the <a href="https://res.cloudinary.com/dqwmahefm/image/upload/v1676533925/pawpedia/static/Pawpedia-Terms-of-Use-Brief-Legal-22-01-2022-Final_nkogvh.pdf" class="grey" target="_blank">Terms &amp; Conditions*</a>
                                </label>
                                <div class="invalid-feedback">{{errors.acceptTerms}}</div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="form-check">
                                <input class="form-check-input rounded-0" type="checkbox" v-model="model.optIn" id="optin" name="Optin" :class="{ 'is-invalid': errors.Optin }" />
                                <label class="form-check-label rounded-0">
                                    Opt in to be the first to know about news and special promotional offers.
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-5 d-flex align-items-md-end justify-content-center">
                        <div class="mt-md-0 mt-3">
                            <button type="submit" class="btn btn-primary rounded-0 px-4" :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                Register
                            </button>
                        </div>
                    </div>                    
                    <div class="row my-4">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div v-if="message" class="alert alert-danger rounded-0" role="alert">{{ message }}</div>
                                <div v-if="!checkErrors(errors)" class="alert alert-danger rounded-0" role="alert">
                                    Please check your details. Invalid or required fields are highlighted in the form above.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>
<script lang="ts">

    import { defineComponent, reactive } from 'vue';
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import * as Yup from 'yup';
    import vSelect from 'vue-select';
    import Swal from 'sweetalert2';
    import axios from 'axios';
    import apiUrl from '../scripts/BaseUrl';
    import { vMaska } from "maska";

    export default defineComponent({
        components: {
            Form,
            Field,
            vSelect,
            Swal,
            ErrorMessage,
            axios
        },
       directives: { maska: vMaska },
       data() {
            return {
                countries: [] as any,
                loading: false,
                message: '',            
                showPassword: false,
                showConfirmPassword: false,
                model: {
                    firstName: '',
                    lastName: '',
                    code: '27',
                    mobile: '',
                    mobileNumber: '',
                    emailAddress: '',
                    country: 'South Africa',
                    password: '',
                    confirmedPassword: '',
                    role: 'User',
                    terms: false,
                    popiConsentGiven: false,
                    optIn: false,
                    ownedPet: ""
                }
            };
        },
        setup() {
            // Non-reactive because it was not explicitly defined with `reactive` or `ref`
            const schema =
                Yup.object().shape({
                    firstName: Yup.string()
                        .min(2, 'First Name must be between 2 and 100 characters.')
                        .max(100, 'First Name must be between 2 and 100 characters.')
                        //.matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
                        .required('First Name is required'),
                    lastName: Yup.string()
                        .min(2, 'Last Name must be more than 2 characters.')
                        .max(100, 'Last Name must be less than 100 characters.')
                        //.matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
                        .required('Last Name is required'),
                    emailAddress: Yup.string()
                        .max(255, 'Email must be less than 255 characters.')
                        .required('Email is required')
                        .email('Email is invalid'),
                    code: Yup.string()
                        .required('Code is required'),
                    mobile: Yup.string()
                        .required('Mobile is required')
                        .min(9, 'Mobile must be 9 or more')
                        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                            'Invalid Phone number'),
                    password: Yup.string()
                        .min(6, 'Password must be at least 6 characters')
                        .max(100, 'Password must be less than 100 characters')
                        .required('Password is required'),
                    retypePassword: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        .required('Retype Password is required'),
                    //country: Yup.string()
                    //    .required('Country is required'),
                    country: Yup.string()
                        .required("Select Country of Origin"),
                    pet: Yup.string()
                        .required('What pet do you have is required'),
                    acceptTerms: Yup.bool()
                        .required('Terms & Conditions is required')
                        .oneOf([true], 'Terms & Conditions is required'),
                    Consent: Yup.bool()
                        .oneOf([true], 'Consent to POPI Act is required'),
                });         
            return {schema};
        },
        methods: {
            onSubmit() {
                this.loading = true;
                if (this.model.code || this.model.mobileNumber) {
                    this.model.mobileNumber = (this.model.code + this.model.mobile.replace(/^0+/, '')).split(" ").join("");
                }
                return axios.post(apiUrl + "/users", this.model)
                    .then((response) => {
                        window.location.href = "/register/thank-you";
                        return response;
                    })
                    .catch((error) => {
                        this.loading = false;

                        if (error.response.data.errors) {
                            var strErrors = "";

                            let values = Object.keys(error.response.data.errors).map(key => error.response.data.errors[key]);
                            for (var errorName of values) {
                                if (Array.isArray(errorName)) {
                                    for (var errorValue of errorName) {
                                        strErrors += errorValue + "\n"
                                    }
                                } else {
                                    strErrors += errorName + "\n"
                                }

                                this.message = strErrors;
                            }


                        } else {
                            this.message = error.response.data.detail;
                        }


                    });
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
            },
            togglePasswordShow() {
                this.showConfirmPassword = !this.showConfirmPassword;
            },
            checkErrors(errors:any) {
                const isObjectEmpty = Object.keys(errors).length === 0;
                return isObjectEmpty;
            },

        },
        async mounted() {
            try {
                const response = await axios.get('/data/CountryCodes.json')
                this.countries = response.data;               
            } catch (err) {
                return err;
            }
        },
        computed: {
            buttonLabel() {
                return (this.showPassword) ? "Hide" : "Show";
            },
            buttonConfirmLabel() {
                return (this.showConfirmPassword) ? "Hide" : "Show";
            }
        },
    })
</script>
