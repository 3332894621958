﻿<template>
    <div class="contact">
        <div class="container-fluid mt-0 mb-5 mt-0 cpad">
            <div class="row">
                <div class="col-md-12 pb-4">
                    <h2>
                        Thank you for your interest in Hill's Pet Nutrition. Have a question? Please check our <a href="/faqs">FAQ section</a> to see if it's already been answered.
                    </h2>
                   
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-md-12 col-xl-9 col-xxl-8 py-3 py-md-4 bg-white">
                    <div class="bg-white">
                        <Form action="#" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, resetForm }" autocomplete="off">
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="name" class="blue">Title</label>
                                    </div>
                                    <div class="form-group mb-3">
                                        <select name="contactTitle" id="title" class="form-select rounded-0" v-model="model.title">
                                            <option value="">&nbsp;</option>
                                            <option value="Mr" selected="">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Miss</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Dr">Dr</option>
                                        </select>                                     
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="name" class="blue">*First Name</label>
                                    </div>
                                    <div class="form-group mb-3">
                                        <Field type="text" v-model="model.firstname" id="name" name="contactFname" class="form-control rounded-0" autocomplete="new-name" :class="{ 'is-invalid': errors.contactFname }" />
                                        <div class="invalid-feedback">{{errors.contactFname}}</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="name" class="blue">*Last Name</label>
                                    </div>
                                    <div class="form-group mb-3">
                                        <Field type="text" v-model="model.lastname" id="name" name="contactLname" class="form-control rounded-0" autocomplete="new-name" :class="{ 'is-invalid': errors.contactLname }" />
                                        <div class="invalid-feedback">{{errors.contactLname}}</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group mb-3 custom-mobile">
                                        <div id="mob" class="mobile-wrapper">
                                            <Field aria-label="Code" class="form-control form-select code rounded-0" v-model="model.code" name="code" as="select" :class="{ 'is-invalid': errors.code }">
                                                <option disabled value="">Code</option>
                                                <option v-for="(country, index) in countries" :value="country.dial_code" :key="index">+{{ country.dial_code }}</option>
                                            </Field>
                                            <Field type="text" v-maska data-maska="### ### ####" placeholder="eg: 082 000 0000" v-model="model.mobile" id="mnumber" name="mobile" class="mobile rounded-0" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" :class="{ 'is-invalid': errors.mobile }" />
                                        </div>
                                        <label for="mob" class="regular">Mobile Number <span>*</span></label>
                                        <div class="errors">
                                            <div class="invalid-feedback">{{errors.code}}</div>
                                            <div class="invalid-feedback">{{errors.mobile}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="email" class="blue">*Email</label>
                                    </div>
                                    <div class="form-group mb-3">
                                        <Field type="text" v-model="model.emailAddress" id="email" name="contactEmail" class="form-control rounded-0" autocomplete="new-email" :class="{ 'is-invalid': errors.contactEmail }" />
                                        <div class="invalid-feedback">{{errors.contactEmail}}</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="name" class="blue">I am a </label>
                                    </div>
                                    <div class="form-group mb-3">
                                        <select name="contactIam" class="form-select rounded-0" v-model="model.iAm">
                                            <option value="Pet Owner">Pet Owner</option>
                                            <option value="Breeder">Breeder</option>
                                            <option value="Practice Manager/Receptionist">Practice Manager/Receptionist</option>
                                            <option value="Retailer">Retailer</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group pb-2">
                                <label for="textarea" class="blue">*Your Question:</label>
                            </div>
                            <div class="form-group mb-3">
                                <Field as="textarea" v-model="model.yourQuestion" class="form-control rounded-0" placeholder="Type here..." autocomplete="new-question" name="contactQuestion" id="textarea" cols="30" rows="10" :class="{ 'is-invalid': errors.contactQuestion }" />
                                <div class="invalid-feedback">{{errors.contactQuestion}}</div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="form-check">
                                    <Field class="form-check-input rounded-0" v-model="model.consent" autocomplete="new-name" type="checkbox" id="consent" name="contactConsent" :value="true" :class="{ 'is-invalid': errors.contactConsent }" />
                                    <label class="form-check-label rounded-0">
                                        I, the data subject, have read and understood this <a href="https://res.cloudinary.com/dqwmahefm/image/upload/v1669023925/pawpedia/static/section-18-consumer-consent-form-v2_uotsks.pdf" class="grey" target="_blank">consent notice</a>
                                        and consent to the processing of my personal information*
                                    </label>
                                    <div class="invalid-feedback">{{errors.contactConsent}}</div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="form-check">
                                    <Field class="form-check-input rounded-0" type="checkbox" v-model="model.terms" id="terms" name="acceptTerms" value="true" :unchecked-value="false" :class="{ 'is-invalid': errors.acceptTerms }" />
                                    <label class="form-check-label rounded-0">
                                        I agree to the <a href="https://res.cloudinary.com/dqwmahefm/image/upload/v1676533925/pawpedia/static/Pawpedia-Terms-of-Use-Brief-Legal-22-01-2022-Final_nkogvh.pdf" class="grey" target="_blank">Terms &amp; Conditions*</a>
                                    </label>
                                    <div class="invalid-feedback">{{errors.acceptTerms}}</div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="form-check">
                                    <input class="form-check-input rounded-0" type="checkbox" v-model="model.optIn" id="optin" name="Optin" :class="{ 'is-invalid': errors.Optin }" />
                                    <label class="form-check-label rounded-0">
                                        Opt in to be the first to know about news and special promotional offers.
                                    </label>
                                </div>
                            </div>
                            <div class="row my-4">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <div v-if="message" class="alert alert-danger rounded-0" role="alert">
                                            {{ message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary rounded-0 px-4" :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Submit</span>
                            </button>
                        </Form>
                    </div>
                </div>
                <div class="col-md-12 col-xl-3 col-xxl-4 py-3 py-md-4">
                    <ul class="py-3">
                        <li class="nobreaks">
                            We're always glad to talk with pet<br />
                            parents and others interested in<br /> 
                            our products and company. If you<br /> 
                            would prefer to speak with us call<br /> 
                            us 0800 228 783 from 9am - 4pm,<br /> 
                            Monday to Friday.
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Postal Address:</strong></li>
                        <li>Hill’s Pet Nutrition Ltd</li>
                        <li>Customer Services</li>
                        <li>P O Box 27136</li>
                        <li>Hout Bay</li>
                        <li>7806</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import { Form, Field } from 'vee-validate';
    import * as Yup from 'yup';
    import axios from 'axios';
    import apiUrl from '../scripts/BaseUrl';

    export default defineComponent({
        components: {
            Form,
            Field,
            axios
        },
        data() {
            return {
                countries: [] as any,
                model: {  
                    topic: 'None',
                    title: '',
                    firstname: '',
                    lastname: '',
                    code: '27',
                    mobile: '',
                    mobileNumber: '',
                    emailAddress: '',
                    preferredLanguage: 'None',
                    iAm: 'Pet Owner',
                    yourQuestion: '',
                    consent: false,
                    optIn: false,
                    contactMe: false,
                    country: 'South Africa',
                }, 
                loading: false,
                message: '',
                errors: [],

            };
        },
        setup() {
            const schema = Yup.object().shape({  
                contactFname: Yup.string()
                    .min(2, 'First Name must be between 2 and 100 characters.')
                    .max(100, 'First Name must be between 2 and 100 characters.')
                    //.matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
                    .required('First Name is required'),
                contactLname: Yup.string()
                    .min(2, 'Last Name must be more than 2 characters.')
                    .max(100, 'Last Name must be less than 100 characters.')
                    //.matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
                    .required('Last Name is required'),
                contactEmail: Yup.string()
                    .max(255, 'Email must be less than 255 characters.')
                    .required('Email is required')
                    .email('Email is invalid'),
                code: Yup.string()
                    .required('Code is required'),
                mobile: Yup.string()
                    .required('Mobile is required')
                    .min(9, 'Mobile must be 9 or more')
                    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                        'Invalid number'),
                contactQuestion: Yup.string()
                    .required('Your Question is required'),    
                contactConsent: Yup.bool()
                    .required('Consent to POPI Act is required')
                    .oneOf([true], 'Consent to POPI Act is required'),
                acceptTerms: Yup.bool()
                    .required('Terms & Conditions is required')
                    .oneOf([true], 'Terms & Conditions is required'),
               
            });    

     

            return {
                schema
            }

        },
        methods: {       
            resetFormFields() {                  
                this.model = {
                    topic: 'None',
                    title: '',
                    firstname: '',
                    lastname: '',
                    code: '27',
                    mobile: '',
                    mobileNumber: '',
                    emailAddress: '',
                    preferredLanguage: 'None',
                    iAm: 'Pet Owner',
                    yourQuestion: '',
                    consent: false,
                    optIn: false,
                    contactMe: false,
                    country: 'South Africa',
                };    
            },
            async onSubmit(value: any) {
                this.loading = true;
                if (this.model.code || this.model.mobileNumber) {
                    this.model.mobileNumber = this.model.code + this.model.mobile.replace(/^0+/, '');
                }
                return axios.post(apiUrl + '/contacts', this.model)
                    .then((response) => {
                        this.loading = true;  
                        window.location.href = '/contact/thank-you';      
                        let textS = document.querySelectorAll<HTMLInputElement>('.form-control');
               
                        for (let i = 0; i < textS.length; i++) {
                            textS[i].value = '';       
                        }

                        let clist = document.getElementsByTagName("input");
                        for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error.response.data.errors) {
                            var strErrors = "";
                            let values = Object.keys(error.response.data.errors).map(key => error.response.data.errors[key]);
                            for (var errorName of values) {
                                if (Array.isArray(errorName)) {
                                    for (var errorValue of errorName) {
                                        strErrors += errorValue + '\n'
                                    }
                                } else {
                                    strErrors += errorName + '\n'
                                }
                            }


                        } else {
                            this.message = error.message;
                     }
                       
                    })    
            },
        },
        async mounted() {
            try {
                const response = await axios.get('/data/CountryCodes.json')
                this.countries = response.data;
            } catch (err) {
                return err;
            }
        }
       
    })
</script>